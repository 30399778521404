<template>
    <div class="test__header">
        <div class="test__header-content">
            <button @click="btnHandler" class="v-btn">{{ btnTitle }}</button>
            <router-link v-if="returnRoute" tag="button" :to="returnRoute" class="v-btn v-btn--transparent test__close-btn">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 1L1 17" stroke="#989CAE" stroke-width="2"/>
                    <path d="M1 1L17 17" stroke="#989CAE" stroke-width="2"/>
                </svg>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestHeader',
    props: ['btnHandler', 'returnRoute', 'btnTitle'],
    components: {
        Icon : () => import('@/components/icon/Icon')
    }
}
</script>

<style lang="scss">
@import "#sass/v-style";
.test {
    &__header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: #FFF;
        margin-left: 300px;
        padding: 20px 24px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        width: calc(100% - 300px);
        z-index: 100;

        @include below($tablet) {
            bottom: 0;
            top: unset;
            width: 100vw;
            margin-left: 0;
            padding: 20px 0;
        }
    }

    &__header-content {
        display: flex;
        width: 100%;
        padding: 0 100px;

        @include below($tablet) {
            padding: 0px 64px;
        }

        @include below($mobile) {
            padding: 0px 16px;
        }
    }

    &__close-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        padding-right: 0;
        margin-left: auto;

        @include below($tablet) {
            padding: 10px;
            margin-left: 12px;
            border: 2px solid #E6EBF3!important;
        }

        svg{
            @include below($tablet) {
                width: 12px;
                height: 12px;
            }
        }

    }
}
</style>
